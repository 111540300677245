<template>
  <div id="my-tracks">
      <div class="log-list"> 
      <div class="list-item" v-for="(item,index) in logList" :key="index" @click="goDetail(item.bizOwid)">
        <div class="pictrue-log-href">
            <img :src="pictrueImg+item.productPic" alt="">
        </div>
        <div class="product-name">{{item.productName}} ({{item.productCode}})</div>
        <div class="products-price">{{item.specsForeign == 1?"$":"￥"}}{{item.specsPromotionprice}}</div>
      </div>
      <div class="clear"></div>
  </div>
  <div class="clear"></div>
   <div class="go-page">
       <Pagination :totalPage="totalPage" :currentPage="currentPage" @pageFun="goPageSrh"/>
   </div>
  </div>
</template>

<script>
import { logList } from './model'
import Pagination from "@/components/component/Pagination.vue";
import { IMGURL } from "@/utils/constant"
export default {
    data(){
        return {
            logList:[],
            totalPage:0,
            currentPage:0,
            currentIndex:0,
            pageNo:1,
            pageSize:8,
            pictrueImg:''
        }
    },
    components:{
        Pagination
    },
    created(){
        this.pictrueImg = IMGURL
        this.getLogList()
    },
    methods:{
        getLogList(){
            logList({pageNo:this.pageNo,pageSize:this.pageSize}).then(res=>{
                this.logList = res.records;
                this.totalPage = res.totalPage;
                this.currentPage = res.currentPage;
                this.currentIndex = res.currentIndex;
            })
        },
        goPageSrh(pageNo){
            this.pageNo = pageNo;
            this.getLogList();
        },
        goDetail(owid){
            this.$router.push({ path: "/productdetails", query: { proId: owid } });
        }
    }
}
</script>
